import React, { useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { FONT_WEIGHT, TextFieldSizes, TYPOGRAPHY } from "@/theme";
import type { PropsWithTheme } from "@/theme";

import type { TextFieldProps } from "./types";

const TextFieldWrapper = styled.div<PropsWithTheme<HTMLDivElement> & TextFieldProps>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	${({ theme, fullWidth }) => css`
		${fullWidth && `width: 100%;`}
		& > label {
			&:disabled {
				color: ${theme.palette.freeze[200]};
			}
		}
	`};
`;

const StyledLabel = styled.label<
	PropsWithTheme<HTMLLabelElement> & Pick<TextFieldProps, "disabled" | "error">
>`
	display: flex;
	flex-direction: row;
	gap: 4px;
	font-weight: ${FONT_WEIGHT.medium};
	${TYPOGRAPHY.body.MD};
	${({ disabled, error, theme }) => css`
		color: ${error
			? `${theme.palette.red[500]}`
			: `${disabled ? `${theme.palette.freeze[500]}` : `${theme.palette.freeze[1000]}`}`};

		& > svg {
			color: ${theme.palette.freeze[400]};
		}
		&:invalid {
			color: red;
		}
	`};
`;

const StyledAsterisk = styled.span<PropsWithTheme<HTMLSpanElement>>`
	${({ theme }) => css`
		color: ${theme.palette.red[500]};
	`};
`;

const StyledInputWrapper = styled.div<
	PropsWithTheme<HTMLInputElement> &
		Pick<TextFieldProps, "disabled" | "error" | "focused" | "textFieldSize">
>`
	align-items: center;
	display: flex;
	justify-content: flex-start;
	gap: 6px;
	padding: 12px 12px 11px 12px;
	outline-color: unset;
	${({ disabled, error, focused, textFieldSize, theme: { tokens, palette } }) => css`
		background-color: ${palette.freeze[0]};
		box-shadow: ${tokens.border.insetRegular} ${error ? palette.red[500] : palette.freeze[200]};
		border-radius: ${tokens.borderRadius.lg};
		${TextFieldSizes[textFieldSize]};

		&:hover {
			box-shadow: ${focused ? tokens.border.insetIncreased : tokens.border.insetRegular}
				${error ? palette.red[500] : palette.freeze[1000]};
		}

		${focused && {
			boxShadow: `${tokens.border.insetIncreased} ${
				error ? palette.red[500] : palette.freeze[1000]
			}`,
		}}

		${disabled && {
			backgroundColor: `${palette.freeze[50]}`,
			boxShadow: `${tokens.border.insetRegular} ${palette.freeze[200]}`,
			color: `${palette.freeze[500]}`,
			"&:hover": {
				backgroundColor: `${palette.freeze[50]}`,
				boxShadow: `${tokens.border.insetRegular} ${palette.freeze[200]}`,
				color: `${palette.freeze[500]}`,
			},
		}}
	`};
`;

const StyledInput = styled.input<PropsWithTheme<HTMLInputElement>>`
	width: 100%;
	border: none;

	/* Hides the native number input arrow controls for Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
		margin: 0;
	}

	/* Hides the native number input arrow controls for Firefox */
	&[type="number"] {
		-moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
	}

	&:focus-visible {
		outline: unset;
	}

	&:disabled {
		background-color: unset;
	}

	${({ theme }) => css`
		&::placeholder {
			color: ${theme.palette.freeze[400]};
		}
	`}
`;

const AdornmentWrapper = styled.div<PropsWithTheme<HTMLDivElement>>`
	cursor: default;
	color: ${({ theme }) => theme.palette.freeze[500]};
`;

const StyledHelperText = styled.span<PropsWithTheme<HTMLSpanElement>>`
	${({ theme }) => css`
		color: ${theme.palette.freeze[400]};
		${TYPOGRAPHY.body.SM};
	`};
`;

const StyledErrorText = styled.span<PropsWithTheme<HTMLSpanElement>>`
	${({ theme }) => css`
		color: ${theme.palette.red[500]};
		${TYPOGRAPHY.body.SM};
	`};
`;

export const TextField = ({
	disabled = false,
	endadornment,
	error,
	errorText,
	fullWidth,
	helperText,
	label,
	name,
	onChange,
	onFocus,
	onKeyDown,
	placeholder,
	required = false,
	startadornment,
	textFieldSize = "XL",
	type = "text",
	...props
}: TextFieldProps) => {
	const [focused, setFocused] = useState(false);

	return (
		<TextFieldWrapper fullWidth={fullWidth}>
			{label && (
				<StyledLabel htmlFor={name} disabled={disabled} error={error}>
					{label}
					{required && <StyledAsterisk>{"*"}</StyledAsterisk>}
				</StyledLabel>
			)}
			<StyledInputWrapper
				disabled={disabled}
				error={error}
				focused={focused}
				textFieldSize={textFieldSize}
			>
				{startadornment && <AdornmentWrapper>{startadornment}</AdornmentWrapper>}
				<StyledInput
					disabled={disabled}
					name={name}
					id={name}
					placeholder={placeholder}
					required={required}
					type={type}
					onBlur={() => setFocused(false)}
					onChange={onChange}
					onFocus={event => {
						setFocused(true);
						onFocus && onFocus(event);
					}}
					onKeyDown={event => onKeyDown && onKeyDown(event)}
					{...props}
				/>
				{endadornment && <AdornmentWrapper>{endadornment}</AdornmentWrapper>}
			</StyledInputWrapper>
			{!error && helperText && <StyledHelperText>{helperText}</StyledHelperText>}
			{error && errorText && <StyledErrorText>{errorText}</StyledErrorText>}
		</TextFieldWrapper>
	);
};
